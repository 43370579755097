import { ErrorState, useVariablesModalStore } from '~/store/variables-modal';
import { useAccountStore } from '~/store/account';

export const useCustomDescription = (description: string) => {
  const { activateVariablesModal, closeVariablesModal } =
    useVariablesModalStore();
  const validateVariables = (errorState: ErrorState): boolean => {
    return !Object.values(errorState).some(Boolean);
  };

  const accountStore = useAccountStore();

  const getCustomDescription = () => {
    let customDescription = '';
    try {
      const doc = JSON.parse(description);
      for (const [index, block] of doc.content.entries()) {
        for (const child of block.content || []) {
          if (child.type === 'text') {
            customDescription += child.text;
          }
          if (child.type === 'VariableChip') {
            customDescription +=
              accountStore.accountVariables[child.attrs.type];
          }
        }
        if (index + 1 !== doc.content.length) {
          customDescription += '\n';
        }
      }
    } catch {
      customDescription = description;
    }

    return customDescription;
  };

  return {
    validateVariables,
    getCustomDescription,
    activateVariablesModal,
    closeVariablesModal,
  };
};
